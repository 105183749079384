import React, {
	useState,
  useEffect
} from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Section, SectionTitle, SectionDescription } from "../../components/Section"
import ProductCardListSale from "../../components/ProductCardListSale"
import NoResult from "../../components/NoResult"
import { FilterGrid, FilterGridItem, FilterLabel, FilterGridOr, OrSep} from "../../components/FilterComponent"


const SectionProductFilter = styled(Section)`
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  position:relative;
  z-index:2;
  margin-bottom:-160px;
  padding:60px 0 200px;
  @media(min-width:1200px){
    margin-bottom:-200px;
    padding:60px 0 260px;
  }
  @media(min-width:1600px){
    margin-bottom:-240px;
    padding:120px 0 300px;
  }


`

const SectionProductGrid = styled(Section)` 
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  padding:0 0 60px;
  @media(min-width:1200px){
    padding:0 0 80px;
  }
  @media(min-width:1600px){
    padding:0 0 120px;
  }
`

const SellingBkg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    opacity:0.07;
  }
`

const FilterContainer = styled.div`
  border:1px solid rgba(255,255,255,0.15);
  border-radius:23px;
  padding:0 20px;
  @media(min-width:1200px){
    padding:0 30px;
  }
  @media(min-width:1600px){
    padding:0 50px;
  }
  .filter-label{
    background-color:#FC0002;
    color:#fff;
    border-radius:15px;
    border-color:transparent;
    margin:15px 0;
    display:block;
    @media(min-width:576px){
      margin:30px 0 0;
    }
    @media(min-width:992px){
      display:inline-block;
      margin:30px 0;
    }
    &:after{
      display:none;
    }
  }
  .sep{
    margin:0;
    @media (min-width: 576px){
      margin:30px 0;
    }
  }
  .form-group-bordered{
    background-color:#272727;
    border-radius:15px;
    box-shadow:none;
    margin:15px 0;
    @media (min-width: 576px){
      margin:30px 0;
    }
    .form-control{
      color:#fff;
      option{
        color:#000;
      }
    }
  }
`

const ProductSection = (props) => {
	const {data, buildingType, buildingApplication, openQuoteModal } = props;
	const [currentCategory, setCategory] = useState('All');
	const [categoryBuildingTypes, setCategoryBuildingTypes] = useState(buildingType);
	const [currentBuildingType, setCurrentBuildingType] = useState('All');
	const [currentProductApp, setProductApp] = useState('All');
	const [currentPriceRange, setPriceRange] = useState('All');
	const [currentRoofStyle, setRoofStyle] = useState('All');

	// const tabClick = useCallback((data) => {
	// 	setCategory(data)
	// }, [currentCategory, setCategory]);

  useEffect(() => {
    var selectedBuildingTypes = []
    if (currentCategory === 'All') {
      selectedBuildingTypes = buildingType
    } else {
      buildingType.forEach(function(item) {
        if (item.node.mainCategory.name === currentCategory) {
          selectedBuildingTypes.push(item)
        }
      })
    }
    setCategoryBuildingTypes(selectedBuildingTypes)
  }, [currentCategory, setCategory]);



  let priceList = [];
  data.edges.forEach(function(item){
    priceList.push(item.node.price)
  })


  const onSetProductApp = (e) => {
    setProductApp(e.target.value);
    setCurrentBuildingType("All");
  }

  const onSetCurrentBuildingType = (e) => {
    setCurrentBuildingType(e.target.value);
    setProductApp("All")
  }

	return(
    <>
      <SectionProductFilter bgColor="#000" >
        <SellingBkg><StaticImage src="../../images/ppc/selling-metal-bkg.jpg" alt="selling-metal-bkg" /></SellingBkg>
        <div className="container">
          
          <SectionTitle color="#fff">America’s Best-Selling Metal Buildings</SectionTitle>
          <SectionDescription color="#fff"><p>Buy today and get your dream building installed & delivered for FREE!</p></SectionDescription>      
          <FilterContainer>                      
            <FilterGrid className="filter-grid">   
              <FilterGridItem>
                <FilterLabel className="filter-label">Filters By : </FilterLabel>
              </FilterGridItem>
              <FilterGridItem>
                  <div className="form-group-bordered">
                    <select
                      id="building_type"
                      className="form-control"
                      onChange={(e) => onSetCurrentBuildingType(e)}
                      value={currentBuildingType}
                      aria-label="building type"
                      >
                        <option value="All">All Building Type</option>
                        {
                          categoryBuildingTypes.map(item => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))
                        }
                    </select>
                  </div>
              </FilterGridItem>
              <FilterGridOr>
                <OrSep color="rgba(255,255,255,0.65)" className="sep">OR</OrSep>
              </FilterGridOr>
              <FilterGridItem>
                  <div className="form-group-bordered">
                    <select
                    id="building_app"
                    className="form-control"
                    onChange={(e) => onSetProductApp(e)}
                    value={currentProductApp}
                    aria-label="building application"
                    >
                      <option value="All">All Building Application</option>
                      {
                        buildingApplication.map(item => (
                          <option value={item.node.name} key={item.node.name}>
                            {item.node.name}
                          </option>
                        ))
                      }
                    </select>
                  </div>                           
              </FilterGridItem>
              <FilterGridItem>
                  <div className="form-group-bordered">
                      <select
                        id="price_range"
                        className="form-control"
                        onChange={(e) => setPriceRange(e.target.value)}
                        aria-label="price range"
                      >
                          <option value="All">All Price Range</option>
                          <option value="0-2000">$0-$2000</option>
                          <option value="2000-5000">$2000-$5000</option>
                          <option value="5000-20000">$5000-$20000</option>
                          <option value="20000">$20000 Above</option>
                      </select>
                  </div>                           
              </FilterGridItem>
            </FilterGrid>
          </FilterContainer>
        </div>
      </SectionProductFilter>
      <SectionProductGrid bgColor="#F1F9FF">
        <div className="container">     
        <div style={{display:'none'}} ><NoResult /></div>   
          <ProductCardListSale
            data={data.edges}
            category={currentCategory}
            buildingType={currentBuildingType}
            buildingApp={currentProductApp}
            price={currentPriceRange}
            roofStyle={currentRoofStyle}
            openQuoteModal={openQuoteModal}
          />
         
        </div>
      </SectionProductGrid>
    </>   
	)
}

export default ProductSection