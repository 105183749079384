import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const WhiteButtonBtn = styled(BaseButton)`
	background: #fff;
	border-color:#fff;
	color:#000;		
	& .icon{
		> svg{
			fill:#000;
		}
	}
	&:hover{
		background-color: #000;
		border-color:#000;
		color:#fff;
        &:before {
			background: #000;
		}
		& .icon{
			> svg{
				fill:#fff;
			}
		}
	
	}
`

const WhiteButton = (props) => {
	const { icon, text } = props;
	return(
		<WhiteButtonBtn className='btn'>
			<span className='icon'>{icon}</span>
            <span className='text'>{text}</span>
		</WhiteButtonBtn>
	)
}

export default WhiteButton