import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { graphql } from 'gatsby' 
import {StaticImage} from "gatsby-plugin-image"
import {Section, SectionTitle, SectionDescription } from "../components/Section"
import {HomeIcon, PhoneIcon, ArrowRightFill, FastDeliveryIcon, RustWarrantyIcon, SeasonWarrantyIcon, ConfigurationIcon, SkilledTeamIcon, ProudBusinessIcon} from "../components/Icons"
import WhiteButton from "../components/Button/WhiteButton"
import PrimaryButton from "../components/Button/PrimaryButton"
import BlackButton from "../components/Button/BlackButton"
import ExpertForm from "../components/ExpertForm"
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"
import QuotePopup from  '../components/QuotePopup'
import Logo  from "../images/logo.svg"
import TopLeftGraphic from "../images/ppc/topleft-graphic.png"
import BottomRightGraphic from "../images/ppc/bottomright-graphic.png"
import ExpertBg from "../images/ppc/expert-bg.png"
import ExpertTeamBg from "../images/ppc/expert-team.png"
import PpcHero from "../images/ppc/ppc-hero.jpg"
import ListArrow from "../images/ppc/ListArrow.svg"

import ProductSection from "../sections/MetalBuildingSale/ProductSection";
import ReviewsSection from "../sections/Home/ReviewsSection"

//Header
const HeaderLanding = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height:54px;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  @media(min-width:1200px){
    height:120px;
    background:transparent;
    box-shadow:none;
  }
`
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;  
  position:relative;
  @media(min-width:1200px){
    background-color:#fff;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
  }
`

const Navbar = styled.div`  
  margin-left: auto;
  display:flex;
  align-items:center;
`
const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;  
  ${BreakpointDown.xl`  
    justify-content: initial;
    padding: 20px 0;      
    max-width: 320px;
    width:100%;
    position: absolute;
    z-index: 99;
    transition: all 0.3s ease 0s;
    transform: translate3d(320px,0,0);
    overflow-y: auto;
    right: -40px;
    background-color:#fff;
    top: 54px;
    height: calc(100vh - 50px);
    box-shadow: 0 3px 26px rgba(0,0,0,0.2);
    align-items: flex-start;
  `}
`

const NavBrand = styled(Link)`   
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;  
  display: flex;
  align-items: center;
  justify-content: center;  
  outline:none;  
  background:#fff;
  border-radius:0 0 35px 35px;
  box-shadow:0 0 1px rgba(0,0,0,0.5);
  width: 124px;
  height: 104px;
  @media(min-width:1200px){
    width: 144px;
    height: 124px;
  }
  @media(min-width:1440px){
    width: 164px;
    height: 144px;
    padding:0;
  }
  > img{
    width:75%;
    @media(min-width:1200px){
      width:100%;
    }
  }
`

const TopNav = styled.div`
  text-align: right;    
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding:9px 50px 9px 0;
  ${BreakpointUp.xl`    
    padding:10px 0;
  `}
  > a, button{
    margin:0 10px;
  }
  .btn{
    padding:10px 20px;
	  font-size:16px;
    @media(max-width:1279px){
      padding: 4px 8px;
    }
    .text{
      @media(max-width:1279px){
        display:none;
      }
    }        
  }

  & .hamburger{
    position: absolute;
    width: 30px;
    height: 30px;
    top: 10px;
    right: 0;
    transition-duration: 0.5s;
    display:none;
    ${BreakpointDown.xl`
      display: inline-flex;      
    `}
    .icon{
      transition-duration: 0.5s;
      position: absolute;
      height: 3px;
      width: 30px;
      top: 15px;
      background-color: #212121;

      &:before {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #212121;
        content: "";
        top: -10px;
        left:0;

      }
      &:after {
        transition-duration: 0.5s;
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: #212121;
        content: "";
        top: 10px;
        left:0;
      }
    }
  }
`

const NavbarNav = styled.div`
  display: flex;
  list-style: none;
  align-items:center;
  justify-content: space-around;
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
    justify-content: inherit;
  `}
`
const NavItem = styled.div`   
  margin:0;  
  .dropdown{
    ${BreakpointUp.xl`      
      display:block;
      visibility: hidden;
      opacity: 0;
      transform: scale(0.8) translateY(-12%);
      transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
    `}
    &.dropdown-megamenu{
      ${BreakpointUp.xl` 
        transform: scale(0.8) translateY(-12%) translateX(-50%);
      `}
    }
  }
  &:hover{
    cursor:pointer;    
    .dropdown{
      ${BreakpointUp.xl`
        display: block;
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: none;
      `}
      &.dropdown-megamenu{
        ${BreakpointUp.xl`
          transform:translateX(-50%);

        `}
      }
    }
  }
  ${BreakpointDown.xl`        
    width:100%;
  `}  
  &.active{
    & .nav-link{
      color:#211F28; 
    }
  }
  &:last-child{
    .dropdown-menu{
      ${BreakpointUp.xl`
        right:0;
        left:auto;
      `}
    }
  }
`

const NavLink = styled.span`
  display: flex;
  align-items:center;  
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color:#333;
  cursor:pointer;
  white-space:nowrap;
  position:relative;
  padding:10px 12px;  
  @media(min-width:1440px){
    padding:10px 15px;  
  }
  > a{
    color:#000;
  }
  &:hover{
    > a{
      color: #000;
    }
  }  
`


//Hero Section
const SectionHero = styled(Section)` 
 .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  padding:54px 0 0;
  @media (min-width: 768px){    
    padding:54px 0 0;
  }
  @media (min-width: 992px){
    padding:220px 0 150px;
  }
  @media (min-width: 1200px){
    padding:220px 0 175px;
  }
  @media (min-width: 1600px){
    padding:230px 0 280px;
  }
`

const HeroBkg = styled.div`
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: ${props=>props.bgImg};
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  justify-content: center;
  padding-top:60px;
  @media (min-width: 992px){
    justify-content: flex-end;
    position: absolute;    
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    padding-top:0 ;
  }
  > .gatsby-image-wrapper {
    display:inline-block !important;
    margin-right: -40px;
    width:100%;
    max-width:300px;
    @media (min-width: 768px){
      max-width:400px;
    }
    @media (min-width: 992px){
      max-width:600px;
    }
    @media (min-width: 1200px){
      max-width:850px;
    }
    @media (min-width: 1440px){
      max-width:1058px;
    }
  }
`
const HeroCover = styled.div`
  .hero-content{
    width:100%;
    text-align:center;
    margin: 0 auto;
    padding:40px 0;
    max-width: 620px;
    @media (min-width: 992px){
      text-align:left;
      margin-left:0;
      padding:0;
    }
    @media (min-width: 1200px){
      max-width: 640px;
    }
    @media (min-width: 1440px){
      max-width:720px;
    }
    .hero-subtitle{
      font-family: 'Bebas Neue', sans-serif;
      text-transform: uppercase;      
      color:#FC0002;

      font-size:45px;
      line-height:45px;
      @media (min-width: 768px){        
        font-size:50px;
        line-height:50px;
      }
      @media (min-width: 992px){        
        font-size:55px;
        line-height:55px;
      }
      @media (min-width: 1200px){        
        font-size:62px;
        line-height:62px;
      }
      @media (min-width: 1600px){
        font-size:64px;
        line-height:64px;
      }
    }
    .hero-title{
      font-weight: 400;
      font-family: 'Bebas Neue', sans-serif;
      color:#000;
      text-transform: uppercase;
      margin-bottom:20px;
      
      font-size:40px;
      line-height:45px;
      @media (min-width: 768px){        
        font-size:45px;
        line-height:50px;
      }
      @media (min-width: 992px){        
        font-size:50px;
        line-height:55px;
      }
      @media (min-width: 1200px){        
        font-size:55px;
        line-height:60px;
      }
      @media (min-width: 1600px){
        font-size:62px;
        line-height:62px;
      }
    }

    .hero-lead{
      color:#0C0C0C;
      margin-bottom:20px;
      width:100%;
      margin:0 auto 20px auto;
      @media (min-width: 768px){
        margin:0 0 20px;
      }
      @media (min-width: 992px){
        font-size:18px;
        line-height:34px;
      }
      @media (min-width: 1440px){
        max-width:380px;
      }
    }
    .btn{
      text-transform:uppercase;
      @media (min-width: 1200px) {
        padding: 11px 40px;
      }
    }
  }
`
const Flag = styled.div`
  position:absolute;
  top:0;
  left:-15px;
  .gatsby-image-wrapper {
    width: 50%;
    @media (min-width: 768px){
      width: 70%;
    }
    @media (min-width: 1440px){
      width: 80%;
    }
    @media (min-width: 1600px){
      width: 100%;
    }
  }
`



//Best Choice
const ChoiceBkg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const ChoiceTitle = styled(SectionTitle)` 
  strong{
    color:#FC0002;
  }
`
const ChoiceGrid = styled.div` 
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  margin:0 -15px;
  .choice-item{
    .icon{
      position: relative;
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0%);
      top: 0px;
      transition: 0.3s;
    }
    .text{
      position: relative;
      &:after {    
        @media(min-width:992px){
          background: none repeat scroll 0 0 transparent;
          bottom: -20px;
          content: "";
          display: block;
          height: 2px;
          left: 50%;
          position: absolute;
          background:rgba(0,0,0,0.5);
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
          width: 0;
          transform: translateX(-50%);
        }
      }
    }
    &:hover{
      .icon{
        top: -5px;
      }
      .text{
        &:after { 
          @media(min-width:992px){
            width: 20%;
          }
        }
      }
    }
    &:nth-child(1){.icon{background-color:#3DD9DF;}}
    &:nth-child(2){.icon{background-color:#B46DEE;}}
    &:nth-child(3){.icon{background-color:#F98658;}}
    &:nth-child(4){.icon{background-color:#FF769F;}}
    &:nth-child(5){.icon{background-color:#FDC548;}}
    &:nth-child(6){.icon{background-color:#34D1AC;}}
    &:hover{
      &:nth-child(1){.icon{box-shadow:0px 10px 15px 0px rgba(61, 217, 223, 0.4);}}
      &:nth-child(2){.icon{box-shadow:0px 10px 15px 0px rgba(180, 109, 238, 0.4);}}
      &:nth-child(3){.icon{box-shadow:0px 10px 15px 0px rgba(249, 134, 88, 0.4);}}
      &:nth-child(4){.icon{box-shadow:0px 10px 15px 0px rgba(255, 118, 159, 0.4);}}
      &:nth-child(5){.icon{box-shadow:0px 10px 15px 0px rgba(253, 197, 72, 0.4);}}
      &:nth-child(6){.icon{box-shadow:0px 10px 15px 0px rgba(52, 209, 172, 0.4);}}
    }
  }

`
const ChoiceItem = styled.div` 
  position:relative;
  width:100%;
  padding:0 15px;
  text-align: center;
  cursor:pointer;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom:30px;
  @media(min-width:992px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    margin-bottom:60px;
  }
  .icon{
    display:inline-flex;
    align-items:center;
    justify-content:center;
    background-color:#3DD9DF;
    width:78px;
    height:78px;
    border-radius:15px;
    margin:0 auto 20px auto;
    > svg{
      fill:#fff;
    }
  }
  .text{
    display:block;
    font-weight:500;
    text-align:center;
    margin:0 auto;
    width:100%;
    color:#000;
    font-size:16px;
    line-height:22px;
    max-width:180px;
    @media(min-width:768px){
      font-size:18px;
      line-height:24px;
      max-width:200px;
    }
    @media(min-width:992px){
      font-size:20px;
      line-height:26px;
      max-width:210px;
    }
    @media(min-width:1200px){
      font-size:24px;
      line-height:30px;
      max-width:270px;
    }
  }
`

//RTO Finance
const RtoFinGrid =  styled.div`
  .rtofin-item{
    + .rtofin-item{
      margin-top:40px;
      padding-top:40px;
      border-top:1px solid #ccc;
      @media(min-width:992px){
        margin-top:80px;
        padding-top:0;
        border-top:none;
      }
    }
    &:nth-child(even){
      @media(min-width:992px){
        flex-direction:row-reverse;
      }
      .circle{
        &:before {
          left: 0;
          transform: translate(0,-50%);
        }
        .gatsby-image-wrapper{
          position: relative;
          top: 8px;
          left: -10px;
        }
      }
    }
  }

`

const RtoFinItem = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:space-between;
  margin:0 -10px;
`
const RtoFinLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 10px;
  @media(min-width:992px){
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const RtoFinRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 10px;  
  display:none;
  @media(min-width:992px){
    flex: 0 0 50%;
    max-width: 50%;
    display:block;
  }
`
const RtoFinTitle = styled((SectionTitle))`
  text-align:left;
  margin-left:0;
  font-weight: 700;
  @media (min-width: 992px){
    font-size: 44px;
    line-height: 50px;
  }
 
`
const RtoFinDescription = styled(SectionDescription)`
  text-align:left;
  margin:0;
  > p{
    margin:0 0 30px;
  }
  & ul{
		list-style: none;
		margin:0 0 30px;
		padding:0;
		font-weight: 500;
		font-size: 16px;
		line-height: 30px;
    @media (min-width: 1440px){
      font-size: 20px;
      line-height: 40px;
    }
		& li {
			padding-left:20px;
			vertical-align: text-top;
			margin:0;
			+ li{
				margin-top:10px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
		}
	}
 
`

const RtoFinCircle = styled.div`
  position:relative;
  display:flex;
  align-items:center;
  justify-content:right;
  margin:0 auto;
  &:before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    border-radius:50%;
    background-color:#0B619B;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    transition: box-shadow 0.3s ease-in-out;
  }
  &:hover{
    &:before{
      box-shadow: -20px 20px 0px #e2f2fe;
    }
  }
  .gatsby-image-wrapper{
    position: initial;
  }
`



//Need Help
const SectionHelps = styled(Section)`
  position:relative;
  background: #c70607;
  background: -moz-linear-gradient(left,  #c70607 0%, #096aab 100%);
  background: -webkit-linear-gradient(left,  #c70607 0%,#096aab 100%);
  background: linear-gradient(to right,  #c70607 0%,#096aab 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c70607', endColorstr='#096aab',GradientType=1 );
  padding:60px 0;
  @media(min-width:1200px){
    padding:80px 0;
  }
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
`
const NeedHelpGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  @media(min-width:768px){
    text-align: left;
  }
`
const NeedHelpGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:768px){
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
`
const NeedHelpTitle = styled(SectionTitle)`
  color:#fff;
  text-align:center;
  font-weight:500;
  @media(min-width:768px){
    text-align:left;
  }  
  @media (min-width: 992px){
    font-size: 42px;
    line-height: 48px;
  }
`
const NeedHelpDesc = styled(SectionDescription)`  
  color:#fff;
  text-align:center;
  margin-bottom:15px;
  @media(min-width:768px){
    text-align:left;
    margin-bottom:0px;
  }
`
const NeedHelpGridRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:768px){
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
`
const CtaWhite = styled.div`
  .btn{
    padding:3px;
    @media (min-width: 1200px){
      font-size: 20px;
    }
    @media (min-width: 1600px){
      font-size: 30px;
    }
    .text{
      padding: 10px 20px;
      @media (min-width: 1200px){
        padding: 20px 20px;
      }
    }
    > .icon{
      background:#FC0002;
      border-radius:50%;
      display:flex;
      align-items:center;
      justify-content:center;
      width:52px;
      height:52px;
      + .text{
        margin-left:0;
      }
      > svg{
        fill:#fff;
        @media (min-width: 1200px){
          width:32px;
          height:32px;
        }
      }
      @media (min-width: 1600px){
        width:74px;
        height:74px;
      }
    }
    &:hover{
      .icon{
        > svg{
          fill:#fff;
        }
      }
    }
  }
`
const CtaPrimary = styled.div`
  text-align:center;
  @media (min-width: 576px){
    text-align:left;
  }
  .btn{
    background: #ff3032;
    background: -moz-linear-gradient(left,  #ff3032 0%, #d20002 80%);
    background: -webkit-linear-gradient(left,  #ff3032 0%,#d20002 80%);
    background: linear-gradient(to right,  #ff3032 0%,#d20002 80%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff3032', endColorstr='#d20002',GradientType=1 );

    padding:3px;
    @media (min-width: 1200px){
      font-size: 20px;
    }
    .text{
      padding: 10px 30px;
      + .icon{
        margin-left:0;
      }
    }
    > .icon{
      background: #d9eaf6;
      background: linear-gradient(to bottom,  #d9eaf6 0%,#ffffff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d9eaf6', endColorstr='#ffffff',GradientType=0 );
      border-radius:50%;
      display:flex;
      align-items:center;
      justify-content:center;
      width:52px;
      height:52px;
      > svg{
        fill:#000;
      }
    }
    &:hover{
      .icon{
        > svg{
          fill:#000;
        }
      }
    }
  }
`


//Footer
const FooterLanding = styled(Section)`
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  padding:40px 0 60px 0;  
  @media(min-width:1200px){
    padding:40px 0 90px 0;  
  }
`
const FooterBkg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`
const FooterGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  z-index: 1;
  text-align: center;
  @media(min-width:768px){
    text-align: left;
  }
`
const FooterGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:768px){
    flex: 0 0 50%;
    max-width: 50%;
  }
`
const FooterGridRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:768px){
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media(min-width:1200px){
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`
const Source = styled.div`
  color:#fff;
  .gatsby-image-wrapper {
    display: inline-block !important;
    width: auto;
  }
`
const SourceTitle = styled.div`
  font-weight:500;
  border-bottom:1px solid #00365A;
  margin-bottom:25px;
  padding-bottom:25px;  
  font-size:20px;
  line-height:32px;
  @media(min-width:1200px){
    font-size:24px;
    line-height:36px;
  }
`
const SourceDesc = styled.div`
  font-size:18px;
  line-height:30px;
  margin-bottom:15px;
  @media(min-width:1200px){
    font-size:22px;
    line-height:36px;
  }
  p{
    margin-bottom:0;
  }
  a{
    color:#fff;
    font-weight:700;    
    &:hover, &:focus{
      color:#FC0002;
    }
  }
`
const Priority = styled.div`
  max-width:370px;
  width:100%;
  margin:0 auto ;
  text-align:center;
  color:#fff;
  > p{
    font-size:20px;
    line-height:28px;
    @media(min-width:1200px){
      font-size:24px;
      line-height:30px;
    }
  }

`
const FlagTag = styled.div`
  position:relative ; 
  margin-bottom:15px;
  .gatsby-image-wrapper {    
    margin: 0 auto;
    width: 50%;
  }
`
const StarSep = styled.div`
  .gatsby-image-wrapper {
    display: inline-block !important;
    width: auto;
  }
`

//Expert
const SectionExpert = styled(Section)`
  overflow:hidden;
  padding:60px 0 90px;
  @media(min-width:1600px){
    padding:90px 0 120px;
  }
  @media(min-width:1600px){
    padding:120px 0;
  }
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  &:after{
    bottom:0;
    right:0;
    top:auto;
    background-size: cover;
    width:110px;
    height:110px;
    @media(min-width:768px){
      width:140px;
      height:140px;
    }
    @media(min-width:992px){
      width:180px;
      height:180px;
    }
    @media(min-width:1600px){
      width:299px;
      height:299px;
    }
  }
`
const ExpertBkg = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    opacity: 0.40;
  }
`
const ExpertContainer = styled.div`
  .container{
    @media(min-width:992px){
      padding-right: calc((100% - 1380px) / 2);
      padding-left:0;
      max-width:inherit;
    }
  }
`
const ExpertGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  margin:0 -15px;
  @media(min-width:1200px){
    margin:0 -30px;
  }
  @media(min-width:1400px){
    margin:0 -40px;
  }
  @media(min-width:1600px){
    margin:0 -60px;
  }
`
const ExpertLeft = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  display:none;
  @media(min-width:992px){
    flex: 0 0 50%;
    max-width: 50%;
    display:block;
  }
  @media(min-width:1200px){
    padding:0 30px;
  }
  @media(min-width:1400px){
    padding:0 40px;
  }
  @media(min-width:1600px){
    padding:0 60px;
  }
  .gatsby-image-wrapper {
    margin-bottom:30px;
    @media(min-width:992px){
      border-radius:0 25px 25px 0;
      margin-bottom:0;
    }
  }
  
  `
const ExpertRight = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:992px){
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  @media(min-width:1200px){
    flex: 0 0 50%;
    max-width: 50%;
    padding:0 30px;
  }
  @media(min-width:1400px){
    padding:0 40px;
  }
  @media(min-width:1600px){
    padding:0 60px;
  }

  .form-action{
    .btn{
      max-width: 300px;
      width: 100%;
    }
  }
`


const ExpertTitle = styled((SectionTitle))`
  text-align:left;
  margin-left:0;
  font-weight: 700;
  @media (min-width: 992px){
    max-width: 550px;
    font-size: 44px;
    line-height: 50px;
  }
 
`
const ExpertDescription = styled(SectionDescription)`
  text-align:left;
  margin:0;
  @media (min-width: 992px){
    max-width: 488px;
  }
  > p{
    margin:0 0 30px;
  }
`

const SectionFinance = styled(Section)`
  .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
  &:after, &:before{
    display:none;
    @media (min-width: 992px){
      display:block;
    }
  }
`





// const MetalBuildingsSale = ({ data, location }) => {
  class MetalBuildingsSale extends React.Component {
    constructor(props) { 
      super(props);
      this.scrollToNode = this.scrollToNode.bind(this);      
      this.state = {
        isMenu:true,
        isQuoteVisible:false,
      }
      this.toggleActive = this.toggleActive.bind(this);   
      this.handleModalOpen = this.handleModalOpen.bind(this);   
      this.handleModalClose = this.handleModalClose.bind(this);   
    }
    scrollToNode(node) {
      node.scrollIntoView({ behavior: 'smooth' });
    }
    componentWillUnmount() {
      document.body.classList.remove('menu-open')   
    }
  
    toggleActive() { 
      this.setState({
        isMenu: ! this.state.isMenu
      })
      document.body.classList.toggle('menu-open', this.state.isMenu)
    }
    
    handleModalOpen () {
      this.setState({
        isQuoteVisible:true,
      })
      document.querySelector("body").classList.add("modal-open")
    }
    handleModalClose () {
      this.setState({
        isQuoteVisible:false,
      })
      document.querySelector("body").classList.remove("modal-open")
    }
    render() {
      const location = this.props.location
      const { data } = this.props      

      const productsData = data.allContentfulProduct
      let allSubCategories = []
      data.allContentfulProduct.edges.forEach(function(item) {
        item.node.productSubCategories.forEach(function(node) {
          allSubCategories.push(node.name)  
        })
      })
      
      const allProductApplications = data.allContentfulProductApplication.edges
      const allRoofStyles = data.allContentfulRoofStyle.edges

      const _ = require('lodash')

      const reviewsData = {
        label: "What Our Customers are sayings",
        title: "Reviews",
        buttonText: "View All Reviews",
        buttonLink: "/reviews",
        reviews: data.allContentfulReview.edges,
      }

    return (
      <Layout location={location} noHeader noFooter noSticky noADS>
        <Seo title="Metal Buildings For Sale" description="Metal Buildings For Sale" />
        <HeaderLanding>
          <div className="container">
            <HeaderRow>
              <NavBrand to ="/">
                <img src={Logo} alt="Coast to Coast Carports, Steel Buildings Contractor and Supplier" width="119" height="80" title="Coast-to-Coast-Carports-Metal-Buildings-Manufacturer"/>
              </NavBrand>
              <Navbar>
                <Nav className="nav">
                  <NavbarNav>                  
                    <NavItem><NavLink className="nav-link" onClick={() => this.scrollToNode(this.home)}>Home</NavLink></NavItem>    
                    <NavItem><NavLink className="nav-link" onClick={() => this.scrollToNode(this.building)}>Metal Buildings</NavLink></NavItem><NavItem><NavLink className="nav-link" onClick={() => this.scrollToNode(this.rto)}>RTO & Financing</NavLink></NavItem>
                    <NavItem><NavLink className="nav-link" onClick={() => this.scrollToNode(this.why)}>Why Coast to Coast</NavLink></NavItem>
                    <NavItem><NavLink className="nav-link" onClick={this.handleModalOpen}>Get Your Quote</NavLink></NavItem>
                  </NavbarNav> 
                </Nav> 
                <TopNav>
                  <a href="https://coasttocoastcarports.sensei3d.com/" target="_blank" rel="noreferrer" className="btn-sm" aria-label="Design Your Building" ><PrimaryButton icon={<HomeIcon />} text="Design Your Building"  /></a>
                  <a href="tel:8666817846" aria-label="(866) 681-7846" className="btn-sm"><BlackButton icon={<PhoneIcon />} text="(866) 681-7846" /></a>
                  <button className="hamburger" onClick={this.toggleActive} aria-label="hamburger" ><div className="icon"></div></button>
                </TopNav>          
              </Navbar> 
            </HeaderRow>    
          </div>      
        </HeaderLanding>
        <main>
          <SectionHero className='section section-hero' ref={(node) => this.home = node}>
            <HeroBkg bgImg={`url(${PpcHero})`}><StaticImage src="../images/ppc/ppc-carport.png" alt="ppc-carport" /></HeroBkg>
            <Flag><StaticImage src="../images/ppc/flag.png" alt="flag" /></Flag>
            <div className="container">
              <HeroCover className="hero-cover">
                <div className="hero-content">
                  <span className="hero-subtitle">America’s</span>
                  <h1 className="hero-title">One of The Largest Manufacturers of Metal Carports & Buildings</h1>
                  <p className="hero-lead">From a standard metal carport to industrial steel buildings, we can customize nearly any steel structure to meet your needs</p>
                  <button type="button"  onClick={this.handleModalOpen} aria-label="Get Your Free Quote Now"><BlackButton icon={<ArrowRightFill />} textBefore="Get Your Free Quote Now" /></button>
                </div>
              </HeroCover>
            </div>
          </SectionHero>

          <Section pt="120px" pb="60px" xpt="60px" xpb="0" bgColor="#F1F9FF" ref={(node) => this.why = node}>        
            <ChoiceBkg><StaticImage src="../images/ppc/choice-bkg.png" alt="choice-bkg" /></ChoiceBkg>
            <div className="container">
              <ChoiceTitle mb="60px">Why <strong>Coast to Coast Carports</strong> Is The Best Choice</ChoiceTitle>
              <ChoiceGrid className="choice-grid">
                <ChoiceItem className="choice-item">
                  <span className="icon"><FastDeliveryIcon /></span>
                  <span className="text">Super-Fast, Free <br />Delivery</span>
                </ChoiceItem>
                <ChoiceItem className="choice-item">
                  <span className="icon"><RustWarrantyIcon /></span>
                  <span className="text">No-Quibble, 20-Year Rust-Through Warranty</span>
                </ChoiceItem>
                <ChoiceItem className="choice-item">
                  <span className="icon"><SeasonWarrantyIcon /></span>
                  <span className="text">All Season Workmanship Warranty</span>
                </ChoiceItem>
                
                <ChoiceItem className="choice-item">
                  <span className="icon"><ConfigurationIcon /></span>
                  <span className="text">1000+ Custom Configurations</span>
                </ChoiceItem>
                <ChoiceItem className="choice-item">
                  <span className="icon"><SkilledTeamIcon /></span>
                  <span className="text">Skilled team of In-House Professionals</span>
                </ChoiceItem>
                <ChoiceItem className="choice-item">
                  <span className="icon"><ProudBusinessIcon /></span>
                  <span className="text">A Proud American Business</span>
                </ChoiceItem>
              </ChoiceGrid>
            </div>
          </Section>

          <div ref={(node) => this.building = node}>
            <ProductSection
              data={productsData}
              buildingType={_.uniq(allSubCategories)}
              buildingApplication={allProductApplications}
              allRoofStyles={allRoofStyles}
              openQuoteModal={this.handleModalOpen}
            />
          </div>
          <SectionExpert
            bgColor="#0B619B"
            bgAfter={`url(${ExpertTeamBg})`}
            bgAfterRepeat="no-repeat"   
            bdrWidth="0"         
            >    
            <ExpertBkg><StaticImage src="../images/ppc/customize-bg.png" alt="customize-bg" /></ExpertBkg>
            <ExpertContainer>
              <div className="container">
                <ExpertGrid className="expert-grid">
                  <ExpertLeft><StaticImage src="../images/building-sale.png" alt="expert-fill" /></ExpertLeft>
                  <ExpertRight>
                    <ExpertTitle color="#fff">Do You Need a Customized Metal Building?</ExpertTitle>
                    <ExpertDescription color="#fff">
                      <p>Just fill the details, our expert team is here to help you create the perfect metal building for your home or business. </p>
                      <ExpertForm states={data.allContentfulState.edges} location={location} />
                    </ExpertDescription>
                  </ExpertRight>
                </ExpertGrid>
              </div>
            </ExpertContainer>
          </SectionExpert>

          <SectionFinance pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff"
            bgBefore={`url(${TopLeftGraphic})`}
            bgBeforeWidth="234px"
            bgBeforeHeight="288px"
            bgBeforeRepeat="no-repeat"        
            topBefore="100px"
            leftBefore="-117px"
            
            bgAfter={`url(${BottomRightGraphic})`}
            bgAfterWidth="117px"
            bgAfterHeight="288px"
            bgAfterRepeat="no-repeat"
            topAfter="auto"
            bottomAfter="100px"
            rightAfter="0px"
            ref={(node) => this.rto = node}
          >        
            <div className="container">
              <RtoFinGrid className="rtofin-grid">
                  <RtoFinItem className="rtofin-item">
                    <RtoFinLeft>
                      <RtoFinTitle maxWidth="665px">Get Your Project Financed Today!</RtoFinTitle>
                      <RtoFinDescription maxWidth="630px">
                        <p>Looking for low payments? What about lenient credit terms?  No matter what your financial situation looks like, you can trust Coast to Coast Carports to provide an affordable solution that fits your wants, needs, and desires.</p>
                        <ul>
                          <li>No money down</li>
                          <li>Finance 100% of the customer's purchase</li>
                          <li>Loan terms up to 180 months (commercial loans up to 60 months)</li>
                          <li>Low-interest rates between 6.9%</li>
                          <li>Low monthly payments</li>
                          <li>Real and true credit decisions - no misleading qualifications</li>
                          <li>Work with both prime and subprime credit scores (as low as 595)</li>
                          <li>Payments start after delivery</li>
                        </ul>
                        <CtaPrimary onClick={this.handleModalOpen}>
                          <button type="button">
                            <PrimaryButton icon={<ArrowRightFill />} textBefore="Apply Now" />
                          </button>
                        </CtaPrimary>
                      </RtoFinDescription>
                    </RtoFinLeft>
                    <RtoFinRight>
                      <RtoFinCircle className="circle"><StaticImage src="../images/ppc/financed.png" alt="financed" /></RtoFinCircle>
                    </RtoFinRight>
                  </RtoFinItem>
                  <RtoFinItem className="rtofin-item">
                    <RtoFinLeft>
                      <RtoFinTitle maxWidth="665px">Rent To Own Your Next Custom Structure</RtoFinTitle>
                      <RtoFinDescription maxWidth="630px">
                        <p>Don’t wait to get the carport or barn you’ve been wanting. Sign up for our rent-to-own program today and start enjoying your steel structure immediately. We are committed to determining your needs and finding a custom metal building and payment plan that fits your particular budget.</p>
                        <ul>
                          <li>No Credit Check</li>
                          <li>Low Down Payment</li>
                          <li>Flexible Payment Terms</li>
                          <li>Payoff With No Penalty</li>
                          <li>24/7 Instant Approval</li>
                          <li>No origination fee on 24 & 36 month term contracts</li>
                        </ul>
                        <CtaPrimary onClick={this.handleModalOpen}>
                          <button type="button">
                            <PrimaryButton icon={<ArrowRightFill />} textBefore="Apply Now" />
                          </button>
                        </CtaPrimary>
                      </RtoFinDescription>
                    </RtoFinLeft>
                    <RtoFinRight>
                      <RtoFinCircle className="circle"><StaticImage src="../images/ppc/rto-custom.png" alt="rto-custom" /></RtoFinCircle>
                    </RtoFinRight>
                  </RtoFinItem>
              </RtoFinGrid>
            </div>
          </SectionFinance>
          <ReviewsSection data={reviewsData} />
          <SectionHelps
            bgBefore={`url(${ExpertBg})`}
            bgBeforeWidth="100%"
            bgBeforeHeight="100%"
            bgBeforeRepeat="no-repeat"
            bottomBefore="0"
            topBefore="auto"
            opacityBefore="0.10"
            bgBeforeSize="cover"
          >
            <div className="container">
              <NeedHelpGrid>
                <NeedHelpGridLeft>
                  <NeedHelpTitle>Couldn’t Find the Building You're Looking For?</NeedHelpTitle>
                  <NeedHelpDesc><p>Don't worry! We will help you in designing, customizing, financing, and delivering your building, so that you can enjoy the best service and the quickest lead times.</p></NeedHelpDesc>
                </NeedHelpGridLeft>
                <NeedHelpGridRight>
                  <CtaWhite>
                    <a href="tel:8666817846" aria-label="(866) 681-7846" className="btn-icon-circle"><WhiteButton icon={<PhoneIcon />} text="(866) 681-7846" /></a>
                  </CtaWhite>
                </NeedHelpGridRight>
              </NeedHelpGrid>
            </div>
          </SectionHelps>

          <FooterLanding>
            <FooterBkg><StaticImage src="../images/ppc/ppc-footer-bg.jpg" alt="Footer Background" /></FooterBkg>
            <div className="container">
              <FooterGrid>
                <FooterGridLeft>
                  <Source>
                    <StaticImage src="../images/ppc/logo-ppc.svg" alt="logo" />
                    <SourceTitle>The Leading Metal Buildings Manufacturer</SourceTitle>
                    <SourceDesc>Call us today for a free quote on your steel building and ask about our financing plans. <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a>
                    </SourceDesc>
                  </Source>
                </FooterGridLeft>
                <FooterGridRight>
                    <Priority>
                      <FlagTag><StaticImage src="../images/ppc/flag-ribbon.png" alt="flag ribbon" /></FlagTag>
                      <p>Quality is Our First Priority American Made – American Proud</p>
                      <StarSep><StaticImage src="../images/ppc/star-sep.png" alt="star sep" /></StarSep>
                    </Priority>
                </FooterGridRight>
              </FooterGrid>
            </div>
          </FooterLanding>
        </main>
        <QuotePopup location={location} isVisible={this.state.isQuoteVisible} onClose={this.handleModalClose} />
      </Layout>
    )
  }
}


export default MetalBuildingsSale

export const pageQuery = graphql`
  query PpcPageQuery {
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulReview {
      edges {
        node {
          reviewText {
            childMarkdownRemark {
              html
            }
          }
          customerName
          state {
            name
            abbreviation
            url
            flag {
              title
              gatsbyImageData
            }
          }
          linkedProduct {
            url
            productName
            productImages {
              title
              gatsbyImageData
            }
          }
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          id
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData(quality: 100)
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProductApplication {
      edges {
        node {
          name
        }
      }
    }
  }
`