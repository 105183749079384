import React, { useState} from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import { Section, ExploreMore } from "../Section"
import BlackButton from "../Button/BlackButton";
import ProductCardLanding from "../ProductCardLanding"

const SectionProductGrid = styled(Section)` 
  z-index: 2;
  background: #F1F9FF;
  border-radius: 15px;
`

const ProductGrid = styled.div`
  position: relative;
  z-index:2;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  gap: 20px;
 
`
const ProductItem = styled.div`
  position: relative;
  width: 100%;
  padding:0 15px;
  @media(min-width:992px){
    width: calc(50% - 10px);
  }

`
const ProductCardListSale = ({
  data,
  category,
  buildingType,
  buildingApp,
  price,
  roofStyle,
  openQuoteModal
}) => {
  var cateogryProductData = []
  if (category === "All") {
    cateogryProductData = data
  } else {
    data.forEach(function(item) {
      if (item.node.productCategory.name === category) {
        cateogryProductData.push(item)
      }
    })
  }
  var typeProductData = []
  if (buildingType === "All") {
    typeProductData = cateogryProductData
  } else {
    cateogryProductData.forEach(function(item) {
      var flag = 0
      item.node.productSubCategories.forEach(function(node) {
        if (node.name === buildingType) {
          flag = flag + 1
        }
      })
      if (flag > 0) {
        typeProductData.push(item)
      }
    })
  }

  var applicationProductData = []
  if (buildingApp === "All") {
    applicationProductData = typeProductData
  } else {
    typeProductData.forEach(function(item) {
      var flag = 0
      item.node.productApplications.forEach(function(node) {
        if (node.name === buildingApp) {
          flag = flag + 1
        }
      })
      if (flag > 0) {
        applicationProductData.push(item)
      }
    })
  }
  var priceProductData = []
  if (price === 'All') {
    priceProductData = applicationProductData
  } else if(price === "0-2000" ) {
    applicationProductData.forEach(function(item){
      if ((parseInt(item.node.price) >= 0) && (parseInt(item.node.price) <= 2000)) {
        priceProductData.push(item)
      }
    })
  } else if(price === "2000-5000" ) {
    applicationProductData.forEach(function(item){
      if ((parseInt(item.node.price) > 2000) && (parseInt(item.node.price) <= 5000)) {
        priceProductData.push(item)
      }
    })
  } else if(price === "5000-20000" ) {
    applicationProductData.forEach(function(item){
      if ((parseInt(item.node.price) > 5000) && (parseInt(item.node.price) <= 20000)) {
        priceProductData.push(item)
      }
    })
  } else {
    applicationProductData.forEach(function(item){
      if (parseInt(item.node.price) > 20000) {
        priceProductData.push(item)
      }
    })
  }

  var roofProductData = []
  if (roofStyle === "All") {
    roofProductData = priceProductData
  } else {
    priceProductData.forEach(function(item) {
      if (item.node.productRoofStyle.name === roofStyle) {
        roofProductData.push(item)
      }
    })
  }

  var productData = roofProductData

  const [showLimit, setShowLimit] = useState(6);

  return (
    <SectionProductGrid>
      <ProductGrid>
        {
          productData?.map((item, i) => {
          if(i<showLimit) {
              return (
              <ProductItem key={v4()}>
                  <ProductCardLanding data={item.node} openQuoteModal={openQuoteModal} />
              </ProductItem>
              )
          } return true
          })
        }
      </ProductGrid>
      {productData?.length > showLimit && (
        <ExploreMore><button type="button" onClick={()=>setShowLimit(showLimit + 6)} aria-label="Load More"><BlackButton text="Load More" /></button></ExploreMore>
      )}
    </SectionProductGrid>
  )
}

export default ProductCardListSale
