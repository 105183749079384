import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const BlackBtn = styled(BaseButton)`		
	background-color: #000;
	border-color:#000;
	color:#fff;	
	& .icon{
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		background-color: #FC0002;
		border-color:#FC0002;
		color:#fff;
		&:before {
			background: #FC0002;
		}
	}
`
const BlackButton = (props) => {
	const { icon, text, textBefore } = props;
	return(
		<BlackBtn className='btn'>
			{ textBefore ? (
				<>
					{ textBefore && <span className='text'>{textBefore}</span>}
					{ icon && <span className='icon'>{icon}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</BlackBtn>
	)
}


export default BlackButton