import React, { useRef } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Section, SectionTitle } from "../../components/Section"
import PrimaryLinkButton from "../../components/Button/PrimaryLinkButton"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import RightLineArrowIcon from "../../components/Icons/RightLineArrowIcon"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SectionReview = styled(Section)`
 .container{
    padding:0 20px;
    @media(min-width:576px){
      padding:0 30px;
    }
  }
`
const ReviewWrap = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  .all-link {
    text-align: center;
  }
`

const ReviewCard = styled.div`
  position: relative;
  width: 100%;
  padding:0 10px;
  
  .review-image-wrap {
    width: 100%;
  }
  .review-text {
    padding: 20px 0 0;
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
  .flag {
    width: 100%;
    margin-top: -25px;
    display: flex;
    align-items: center;
    position: relative;
    .gatsby-image-wrapper {
      width: 80px;
      border: 10px solid white;
      border-radius: 80px;
    }
    .flag-text {
      width: calc(100% - 60px);
      background: #0b619b;
      padding: 5px 10px 5px 30px;
      margin-left: -20px;
      color: #fff;
      span {
        margin-left: 5px;
      }
      a {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
      }
      .flag-title{
        font-size:16px;
        line-height:24px;
      }
      .flag-state{
        > span{
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 960px) {
    width: 100%;
  }
`

const SlickSlider = styled.div`
  /* flex-wrap:wrap;
  margin:0 -10px;  
  justify-content:center; */
  .slick-arrow{
    display:none !important;
  }
`
// const SlickItem = styled.div`
//   display: block !important;
//   outline: none;
//   position: relative;  
//   padding: 0 10px;
//   .card{
//     max-width:624px;
//     width:100%;
//     margin: 0 auto;
//   }
// `

const SlickArrowTrack = styled.div`
  display: block;
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  right: 0;
  min-height: 30px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  left: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #0b619b;
  svg {
    fill: #fff;
  }
  &:hover {
    opacity: 0.8;
  }
`
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  right: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: #fff;
  }
  background-color: #0b619b;
  &:hover {
    opacity: 0.8;
  }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowRightIcon />
    </div>
  )
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowLeftIcon />
    </div>
  )
}

const ReviewsSection = ({ data }) => {
  // const reviewsData = {
  //   reviews: data.reviews,
  //   buttonLink: data.buttonLink,
  //   buttonText: data.buttonText,
  // }

  const slider = useRef()

  const next = () => {
    slider.current.slickNext()
  }
  const previous = () => {
    slider.current.slickPrev()
  }
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    nextArrow: <CircleNextArrow />,
    prevArrow: <CirclePrevArrow />,
    arrow: false,
    centerMode: false,
    centerPadding: "60px",
    infinite: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          centerMode: true,
          slidesToShow: 3,
          centerPadding: "5px",
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
           centerMode: true,
          centerPadding: "0px",
        },
      },
    ],
  }
  return (
    <SectionReview
      pt="120px"
      pb="90px"
      xpt="60px"
      xpb="60px"
      bgColor="#F7F7F7"
    >
      <div className="container">
        <ReviewWrap>
          <SectionTitle data-shadowtext={data.label} mb="10px">
            {data.title}
          </SectionTitle>
          <SlickSlider>
            <Slider {...settings} ref={slider}>
              {data.reviews.map((item, i) => {
                if (i < 10 && item.node.linkedProduct.productCategory?.name) {                  
                  return (
                    <ReviewCard key={i}>
                      <div className="review-image-wrap">
                        <GatsbyImage
                          image={
                            item.node.linkedProduct.productImages.gatsbyImageData
                          }
                          alt="item.productName"
                        />
                      </div>
                      <div className="flag">
                        <GatsbyImage image={item.node.state.flag.gatsbyImageData} alt="item.productName"/>
                        <div className="flag-text">
                          <div className="flag-title">{item.node.linkedProduct.productName}</div>
                          <div className="flag-state">Installed in <span>{item.node.state.name}</span></div>
                        </div>
                      </div>
                      <div className="review-text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.node.reviewText.childMarkdownRemark.html,
                          }}
                        />
                      </div>
                      <div className="h6">- {item.node.customerName}</div>
                    </ReviewCard>
                  )
                }
                return true
              })}
            </Slider>
            <SlickArrowTrack>
              <LeftArrow onClick={() => previous()}>
                <ArrowLeftIcon />
              </LeftArrow>
              <RightArrow onClick={() => next()}>
                <ArrowRightIcon />
              </RightArrow>
            </SlickArrowTrack>
          </SlickSlider>
          <div className="all-link">
            <Link to={data.buttonLink}>
              <PrimaryLinkButton icon={<RightLineArrowIcon fill="red" />} text={data.buttonText} />
            </Link>
          </div>
        </ReviewWrap>
      </div>
    </SectionReview>
  )
}

export default ReviewsSection
