import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  PhoneIcon,
  WidthLineIcon,
  LenghtLineIcon,
  HeightLineIcon,
  TickIcon,
  QuoteIcon,
} from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"
import BlackButton from "../Button/BlackButton"
import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import CardPattern from "../../images/ppc/card-pattern.png"
import commaNumber from 'comma-number'

const CardProduct = styled.div`   
  position: relative;
  padding:10px;
  background-color:#fff;
  border-radius:15px;
  background-image: ${props=>props.bgImg};
  background-repeat:no-repeat;
  background-position:0 0;
  background-size:cover;
  margin-bottom:30px;
  @media(min-width:1200px){
    margin-bottom:40px;
  }
  @media(min-width:1600px){
    margin-bottom:60px;
  }
`
const CardProductHeading = styled.div`
  padding:60px 15px 15px 15px;
  ${BreakpointUp.sm`	
    padding:60px 10px 20px 10px;
  `}
  ${BreakpointUp.xl`	
    padding:60px 30px 20px;
  `}
`
const CardProductTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin: 0 0 5px;
  ${BreakpointUp.lg`
    margin: 0 0 10px;  
    font-size: 24px;
    line-height: 34px;
  `}
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const CardProductFigure = styled.div`
  position: relative;
  width: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 15px;
  }
  > a{
    display:block;
    position:relative;
  }
`

const ProductSize = styled.ul`
  display: flex;
  flex-wrap:wrap;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 0 -40px;
  padding:5px 10px;
  @media(min-width:576px){
    padding:15px;
    margin: 0 0 -30px;
  }
  @media(min-width:768px){
    margin: 0 20px -30px;
    padding:15px 30px;
  }
  @media(min-width:1440px){
    margin: 0 30px -30px;
  }
  &:after{
    content: '';
    border-radius: 15px;
    background-color: rgba(255,255,255,0.3);
    border: 1px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  }
  
  `
const ProductItemSize = styled.li`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom:0;
  .icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
        width:auto;
      `}
    }
    + .text {
      margin-left: 8px;
      ${BreakpointUp.lg`		
        margin-left:0;
      `}
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    align-items: normal;
    flex-direction: column;
    font-weight: 700;
    color: #000;
    ${BreakpointUp.lg`		
      align-items: center; 
      flex-direction: row;
    `}
    small {
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      ${BreakpointUp.sm`		
        font-size:14px;              
        line-height:24px
      `}
      + strong {
        margin-left: 0;
        ${BreakpointUp.lg`		
          margin-left:5px;
        `}
      }
    }
    strong {
      display: block;
      font-size: 12px;
      line-height: 18px;
      ${BreakpointUp.sm`		
        font-size:14px;              
        line-height:24px
      `}
    }
  }
`
const CardProductBody = styled.div`
  position: relative;
`

const FinanceOption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media(min-width:576px){
    margin-left: -10px;
  }
  
`
const FinanceType = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  width:100%;
  margin-bottom:10px;
  @media(min-width:576px){
    flex-direction: column;
    width:auto;
    margin-bottom:0;
  }
  ${BreakpointUp.lg`		
    font-size:16px;
	`}
`
const FinanceInfo = styled.div`
  padding: 0 15px;
  ${BreakpointUp.xl`	
    padding:0 25px;
  `}
`
const FinanceItem = styled.div`
  display: ${props => props.display};
  align-items: center;
  opacity: 0.5;
  color: #000;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  @media(min-width:576px){
    padding: 0 15px 0 25px;
  }
  ${BreakpointUp.sm`		
      padding: 0 0 0 30px;
	`}
  ${BreakpointUp.xl`		
    padding: 0 25px 0 50px;         
	`}
  &:last-child {
    @media(min-width:576px){
      margin-top: 10px;
    }
  }
  &:hover {
    cursor: pointer;
  }
  .icon {
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 3px solid #dfe3ef;
    ${BreakpointUp.sm`		
        width: 30px;
        height: 30px;
        border: 6px solid #DFE3EF;
    `}
    + .text {
      margin-left: 5px;
    }
    svg {
      fill: #fc0002;
      width: 8px;
      ${BreakpointUp.sm`		
          width:12px;
      `}
    }
  }
  &:after,
  &:before {
    left: 0;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    display:none;
    @media(min-width:576px){
      display:block;
    }
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #f7f7f7;
    border-width: 10px;
    margin-top: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #dee1ea;
    border-width: 13px;
    margin-top: -13px;
  }
  &.active {
    opacity: 1;
    &:after,
    &:before {
      opacity: 1;
    }
  }
`
const FinancePane = styled.div`
  display: ${props => props.display};
  .label {
    color: #999;
    font-weight: 500;
    margin: 0;
    font-size: 14px;
  }
  button {
    color: #fc0002;
    font-size: 20px;
    font-weight: bold;

    .icon {
      line-height: 20px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      svg {
        fill: #fc0002;
        ${BreakpointUp.sm`      
                    width: 16px;
                `}
      }
    }
    &:hover,
    &:focus {
      color: #0b619b;
      svg {
        fill: #0b619b;
      }
    }
  }
`

const ProductSku = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 1px;
  font-weight: 700;
  background-color: rgba(255,255,255, 0.7);
  padding: 0 10px;
`
const Price = styled.div`
  small {
    color:#575757;
    font-size: 20px;
    ${BreakpointUp.lg`		
        font-size:24px;            
    `}
  }
  strong {
    color: #fc0002;
    font-weight: 700;
    font-size: 20px;
    ${BreakpointUp.lg`		
        font-size:30px;            
    `}
  }
`
const ListPayment = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  ${BreakpointUp.sm`		
        margin: 0 -10px;        
    `}
`
const ListItemPayment = styled.li`
  margin-bottom: 0;
  padding: 0 5px;
  ${BreakpointUp.sm`		
        padding: 0 10px;
    `}
  strong {
    color: #000;
    font-size: 14px;
    line-height: 18px;
    ${BreakpointUp.sm`		
            font-size:16px;
            line-height: 20px;
        `}
  }
  span {
    display: block;
    color: #999;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    ${BreakpointUp.sm`		
        font-size:14px;
        line-height: 20px;
    `}
  }
`

const ButtonToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 -10px;
  padding: 20px;
  flex-direction: column;
  ${BreakpointUp.sm`  
    flex-direction: row;
  `}
  ${BreakpointUp.lg`    
    padding:20px 15px;
  `} 
  a, button {
    padding: 0 10px;
    ${BreakpointDown.sm`    
      width:100%;
    `}
    + a, + button {
      ${BreakpointDown.sm`
        margin-top:15px;                
      `}
    }
  }
  .btn {
    ${BreakpointDown.sm`    
      width:100%;
    `}
    @media(min-width: 1200px){
      padding: 16px 30px;
    }
  }
`

const ProductCardLanding = ({ openQuoteModal, data }) => {
  const [finance, setFinance] = useState("rto")

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            loanPrice {
              apr_36
              apr_48
              apr_60
            }
          }
        }
      }
    `
  )

  const rto_36_price = (
    data.price / site.siteMetadata.loanPrice.apr_36
  ).toFixed(2)
  const rto_48_price = (
    data.price / site.siteMetadata.loanPrice.apr_48
  ).toFixed(2)
  const rto_60_price = (
    data.price / site.siteMetadata.loanPrice.apr_60
  ).toFixed(2)

  return (
    <CardProduct className="card" bgImg={`url(${CardPattern})`}>
      <CardProductFigure className="card-img">
        <Link to={data.url} aria-label={data.productName}>
          <ProductSku><span>SKU No:</span> {data.productSku}</ProductSku>
          <GatsbyImage
            image={data.productImages.gatsbyImageData}
            alt={data.productImages.title}
          />
        </Link>
        <ProductSize className="measurement">
          <ProductItemSize><span className="icon"><WidthLineIcon /></span><span className="text"><small>Width</small><strong>{data.width}</strong></span></ProductItemSize>
          <ProductItemSize><span className="icon"><LenghtLineIcon /></span><span className="text"><small>Length</small><strong>{data.width}</strong></span></ProductItemSize>
          <ProductItemSize><span className="icon"><HeightLineIcon /></span><span className="text"><small>Height</small><strong>{data.height}</strong></span></ProductItemSize>
        </ProductSize>
      </CardProductFigure>

      <CardProductBody className="card-body">
        <CardProductHeading>
          <Link to={data.url} aria-label={data.productName}><CardProductTitle className="card-title">{data.productName}</CardProductTitle></Link>
          <Price><small>Starting Price :</small> <strong>${commaNumber(data.price)}*</strong></Price>
        </CardProductHeading>

        <FinanceOption className="finance-option">
          <FinanceType>
            <FinanceItem
              className={finance === "rto" ? "active" : ""}
              onClick={() => setFinance("rto")}
            >
              <span className="icon"><TickIcon /></span>
              <span className="text">RTO Available</span>
            </FinanceItem>
            <FinanceItem
              className={finance === "financing" ? "active" : ""}
              onClick={() => setFinance("financing")}
            >
              <span className="icon"><TickIcon /></span>
              <span className="text">Financing Available</span>
            </FinanceItem>
          </FinanceType>
          <FinanceInfo>
            <FinancePane>
              <div className="label">Monthly RTO Payment</div>
              <ListPayment>
                <ListItemPayment>
                  <strong>${rto_36_price}</strong>
                  <span>36 Months</span>
                </ListItemPayment>
                <ListItemPayment>
                  <strong>${rto_48_price}</strong>
                  <span>48 Months</span>
                </ListItemPayment>
                <ListItemPayment>
                  <strong>${rto_60_price}</strong>
                  <span>60 Months</span>
                </ListItemPayment>
              </ListPayment>
            </FinancePane>
          </FinanceInfo>
        </FinanceOption>

        <ButtonToolbar>
          <a href="tel:8666817846" aria-label="(866) 681-7846"><PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" /></a>
          <button type="button" onClick={openQuoteModal} aria-label="Request A Quote"><BlackButton icon={<QuoteIcon />} text="Request A Quote" /></button>
        </ButtonToolbar>
      </CardProductBody>
    </CardProduct>
  )
}

export default ProductCardLanding